import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import {
  Heading,
  Inline,
  Message,
  Spacer,
  Text,
  Typography
} from '@te-digi/styleguide'

import { Content } from '../components/Content'
import { Section } from '../components/Section'

const StyledContent = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "changelog" } }) {
        html
      }
    }
  `)

  return (
    <Content
      heading="Työmarkkinatori Style Guide"
      lead="Style Guide sisältää käyttöliittymäkomponentteja ja ohjeistuksia Työmarkkinatorin suunnittelijoiden ja kehittäjien käyttöön. Tämän yhteisen työkalun avulla rakennamme digitaalisista työelämän palveluista helppokäyttöisiä, saavutettavia ja käyttökokemukseltaan yhtenäisiä."
    >
      <Section>
        {(data.markdownRemark.html as string)
          .replace(/<p>/g, '<p class="mb-0">')
          .replace(/<code>/g, '<code class="text-secondary">')
          .replace(/<h1>/g, 'NEXT_VERSION<h1>')
          .replace(/<h2>/g, '<h3 class="h4">')
          .replace(/<h3>/g, '<h4 class="h5">')
          .replace(/<\/h3>/g, '</h4>')
          .replace(/<\/h2>/g, '</h3>')
          .split('NEXT_VERSION')
          .slice(0, 30)
          .filter(Boolean)
          .map(section => {
            const parts = /<h1>(.*?).-.(.*?)<\/h1>(.*)/s.exec(section)
            const [, heading, date, content] = parts || []

            return (
              <Fragment key={heading}>
                <Spacer marginBottom="md">
                  <Inline
                    alignItems="end"
                    justifyContent="between"
                  >
                    <Heading
                      level={2}
                      size={3}
                      noMargin
                      id={heading}
                    >
                      {heading}
                    </Heading>
                    <Text color="dark">{date}</Text>
                  </Inline>
                </Spacer>
                <Spacer marginBottom="layout-md">
                  <Message key={heading}>
                    <Typography>
                      <StyledContent
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </Typography>
                  </Message>
                </Spacer>
              </Fragment>
            )
          })}
      </Section>
    </Content>
  )
}

export default Page
